import React, { useState } from "react"
import Modal from "react-modal"

import { Card } from "./Card"
import { CloseModalButton } from "./CloseModalButton"
import Brain from "-!svg-react-loader!../../../svg/activity.svg"

const ModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    maxWidth: "1024px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

const IntelektualniRazvoj = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return (
    <>
      <Card>
        <Brain />
        <h2>Интелектуални развој</h2>
        <button onClick={openModal}>Детаљније...</button>
      </Card>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={ModalStyles}
        contentLabel="Интелектуални развој"
      >
        <p>
          Предшколско васпитање и образовање је процес раног учења и формирања
          личности дјетета од рођења до поласка у школу, који се одвија у свим
          окружењима у којима дијете живи. За сваку дјевојчицу и сваког дјечака
          предшколског узраста је најбитнија подршка у интеракцији са окружењем,
          у којој се кроз сопствену активност учи и развија.
        </p>
        <ul>
          <li>
            Дјечија игра, као реално најбогатији и најповољнији природни оквир
            за учеће активности, јер је познато да дјеца у правилу теже да сваку
            животну активност најрадије претворе у игровну;
          </li>
          <li>
            Посматрање и демонстрирање садржаја којима се утиче на све врсте
            чулног опажања, стицања и богаћења чулних искустава која омогућавају
            упознавање предмета и појава у окружењу, њихове намјене, откривање и
            стабилизивање искуствених сазнања, њихово проширивање и духовно
            обогаћивање;
          </li>
          <li>
            Метода говора, заснована на живој ријечи васпитача, приповиједању,
            причању, објашњавању, упућивању, комуникацијским потребама групе,
            подстицању интересовања и заснивања учења на осјећањима, јачању
            вољних снага за рад и подизању самопоуздања;
          </li>
          <li>
            Метода рада са дјечијом књигом и текстовним материјалима, већ у
            раној фази са различитим циљевима, од култивисања и буђења првих
            интересовања до продубљивања сазнања и усмјеравања сазнајних
            активности ка зони наредног развоја, неизбјежна је и важна за брже
            осамостаљивање и утицај на развој правилног односа према књизи
            уопште;
          </li>
          <li>
            Практичан рад и вјежбање је незаобилазна метода због емпиријске
            природе учења у овој доби, а осим тога омогућава задовољавање
            различитих интересовања и постизање оптималног трајања активности;
          </li>
          <li>
            Метода стимулисања и храбрења на активност, што подразумијева низ
            поступака награђивања и позитивних (охрабрујућих) исхода учења, што
            је вјероватно најважнија улога васпитача у помјерању активности
            учења на дијете, избјегавање академског поучавања.
          </li>
        </ul>
        <CloseModalButton onClick={closeModal}>Затвори</CloseModalButton>
      </Modal>
    </>
  )
}

export default IntelektualniRazvoj
