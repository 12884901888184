import React, { useState } from "react"
import Modal from "react-modal"

import { Card } from "./Card"
import { CloseModalButton } from "./CloseModalButton"
import Heart from "-!svg-react-loader!../../../svg/activities/heart.svg"

const ModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    maxWidth: "1024px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

const SocijalnoEmocionalniRazvoj = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return (
    <>
      <Card>
        <Heart />
        <h2>Социјално-емоционални развој и развој личности</h2>
        <button onClick={openModal}>Детаљније...</button>
      </Card>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={ModalStyles}
        contentLabel="Социјално-емоционални развој и развој личности"
      >
        <ul>
          <strong>До три године</strong>
          <li>
            Његовање спонтаности и искрености дјетета у контакту са својим
            окружењем и отворености за доживљаје;
          </li>
          <li>
            Његовање одговарајућих механизама самоконтроле, важних за брже
            осамостаљивање;
          </li>
          <li>
            Подршка развoју одговарајуће слике о себи, сопственим могућностима и
            стицању повјерења у своје способности;
          </li>
          <li>
            Подршка усвајању сазнања о правилима и нормама понашања у оквиру
            основних моралних вриједности (добро-рђаво, штетно, опасно-корисно,
            ружно-лијепо);
          </li>
          <li>
            Подршка развоја примарних социјалних компетенција; уважавања потреба
            друге дјеце и одраслих, навика за заједничко обављање активности;
          </li>
          <li>Утицај на формирање, испољавање и контролу осјећања;</li>
          <li>Култивисање и развој позитивних, интегративних емоција.</li>
        </ul>
        <ul>
          <strong>До шест година</strong>
          <li>
            Његовање спонтаности и искрености дјетета у контакту са својим
            окружењем и отворености за доживљаје;
          </li>
          <li>
            Његовање одговарајућих механизама самоконтроле, важних за брже
            осамостаљивање;
          </li>
          <li>
            Проширивање и сређивање социјалног искуства дјетета стеченог у
            породици, разумијевање потреба других људи и односа у ужем
            социјалном окружењу;
          </li>
          <li>
            Упознавање друштвеног живота околине, прилагођено достигнутом нивоу
            развоја и развојним могућностима дјетета;
          </li>
          <li>
            Успостављање адекватне социјалне интеракције са окружењем и бржи
            развој самоконтроле, социјалних компетенција и одговорности;
          </li>
          <li>
            Осамостаљивање у основним социјалним функцијама; размјене мишљења,
            препознавања осјећања, мотива и намјера;
          </li>
          <li>
            Развијање повјерења у властите могућности и самопоуздања, али и
            способности превазилажења тешкоћа у контакту са другим људима,
            развој особина отворености, спремности за сарадњу, разумијевања и
            поштовања других људи, њихових потреба, жеља и преокупација;
          </li>
          <li>
            Разумијевање сопственог поријекла, породичних и родбинских веза,
            културне и духовне баштине окружења.
          </li>
        </ul>
        <CloseModalButton onClick={closeModal}>Затвори</CloseModalButton>
      </Modal>
    </>
  )
}

export default SocijalnoEmocionalniRazvoj
