import React, { useState } from "react"
import Modal from "react-modal"

import { Card } from "./Card"
import { CloseModalButton } from "./CloseModalButton"
import Gym from "-!svg-react-loader!../../../svg/activities/gym.svg"

const ModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    maxWidth: "1024px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

const FizickiRazvoj = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return (
    <>
      <Card>
        <Gym />
        <h2>Физички развој</h2>
        <button onClick={openModal}>Детаљније...</button>
      </Card>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={ModalStyles}
        contentLabel="Физички развој"
      >
        <p>
          Програмирана и планирана физичка активност благотворно дjелује на
          здравствени статус и психосоцијално стање. Због усвајања позитивних
          навика за вjежбање и општег развоја (интелектуалног, социјалног,
          моторичког итд.), веома је битно упражњавати организоване облике
          физичке активности од најранијих фаза одрастања.
        </p>
        <ul>
          <li>
            Јачање физичког здравља и отпорности дјеце као елементарних услова
            правилног развоја свих органа и органских система у овој узрасној
            доби;
          </li>
          <li>
            Подстицање развоја цјеловитог сензорног и перцептивног потенцијала
            дјетета, чула одговорних за правилну перцепцију и доживљај окружења;
          </li>
          <li>
            Овладавање сопственом моториком као сложеним процесом психомоторног
            учења;
          </li>
          <li>
            Развијање навика (тјелесне хигијене, храњења, контроле излучивања.);
          </li>
          <li>Здраво, физички добро и складно развијено дијете;</li>
          <li>
            Богаћење моторичког искуства везаног са свим осталим искуствима;
          </li>
          <li>
            Познавање сопственог тијела, његових могућности, функција чула,
            органа и органских система у активностима игре и кретања;
          </li>
          <li>
            Овладавање локомоторним покретима, усавршавање фине моторике и
            вољног усмјеравања покрета, координације покрета, ритма, снаге,
            тачности, темпа и размака покрета руке, шаке, прстију у вези са
            развојем мишљења и визуелно-просторних способности, прецизности
            опажања, оријентације у простору;
          </li>
          <li>
            Правилан развој нервног система и латерализације, подржавање развоја
            оне стране кортекса која управља функцијама дјетета у процесу учећих
            активности (природна љеворукост и дешњаштво);
          </li>
          <li>
            Развијање хигијенско здравствених и еколошких навика и културе.
          </li>
        </ul>
        <CloseModalButton onClick={closeModal}>Затвори</CloseModalButton>
      </Modal>
    </>
  )
}

export default FizickiRazvoj
